import React from 'react';
import { graphql, StaticQuery } from 'gatsby';
import Img from 'gatsby-image';
import { ParallaxProvider, Parallax } from 'react-scroll-parallax';
import 'intersection-observer';
import Observer from '@researchgate/react-intersection-observer';
import Layout from '../../components/layout';
import Transition from '../../components/transition';
import Header from '../../components/header';
import Footer from '../../components/footer';
import { InitTransition } from '../../components/init-transition';
import InitAnimation from '../../components/init-animation';
import Style from '../../styles/services/services.module.scss';

class Mediagrowth extends React.Component {
  constructor() {
    super();
    this.child = React.createRef();
    this.initAnimation = new InitAnimation();
  }

  componentDidMount() {
    window.scrollTo(0, 0);
    const isMediagrowth = 'Mediagrowth';
    const headingMediagrowth = document.querySelector('.init_a_headingMediagrowth');
    const introServices = document.querySelector('.init_a_introServices');
    setTimeout(() => {
      this.initAnimation.heading(headingMediagrowth, isMediagrowth);
      this.initAnimation.intro(introServices);
    }, 1000);
  }

  serviceList = ({ isIntersecting }, unobserve) => {
    if (isIntersecting) {
      const serviceItem = document.querySelectorAll('.init_a_serviceItem');
      this.initAnimation.list(serviceItem);
      unobserve();
    }
  };

  serviceSkill = ({ isIntersecting }, unobserve) => {
    if (isIntersecting) {
      const strategySkill = document.querySelector('.init_a_serviceSkill');
      this.initAnimation.list(strategySkill);
      unobserve();
    }
  };

  render() {
    const MediagrowthQuery = graphql`
      {
        illustration: file(relativePath: { eq: "illustration/services_mediagrowth.png" }){
          childImageSharp {
            fluid(maxWidth: 703, quality: 70) {
              ...GatsbyImageSharpFluid_noBase64
            }
          }
        },
        badge: file(relativePath: { eq: "illustration/icon_mediagrowth.png" }){
          childImageSharp {
            fluid(maxWidth: 280, quality: 70) {
              ...GatsbyImageSharpFluid_noBase64
            }
          }
        },
      }
    `;

    const transition = {
      InitTransition,
      animation: () => {
        this.child.current.exitAnimation(transition.InitTransition.length);
      },
    };

    const options = {
      root: null,
      rootMargin: '-50% 0px',
      threshold: 0,
    };

    const optionsSkill = {
      root: null,
      rootMargin: '-50px',
      threshold: 0,
    };

    return (
      <StaticQuery
        query={MediagrowthQuery}
        render={(data) => (
          <Layout>
            <Transition ref={this.child}>
              <Header transition={transition} current="services" />
              <ParallaxProvider>
                <main className={`services ${Style.services}`}>
                  <section className={`top ${Style.top}`}>
                    <div className={Style.left}>
                      <h1 className="heading">
                        <span className="text">Media<br className="spTabHDisBlock" />Growth</span>
                        <span className="clip clipMediagrowth init_a_headingMediagrowth">Media<br className="spTabHDisBlock" />Growth</span>
                      </h1>
                      <p className={`intro init_a_introServices ${Style.intro}`}>グロースハックの思考や手法に基づき、ウェブサイトの定期的なデータ解析や運用サポートなどを行い、継続的なチェックとプロダクトの改善提案を実行します。メディアの成長を促し、ユーザーの満足度を最大化します。</p>
                    </div>
                    <Parallax className={`${Style.illustration} ${Style.illustrationMediagrowth}`} x={[5, -5]} tagInner="figure">
                      <Img
                        className={Style.illustrationImg}
                        fluid={data.illustration.childImageSharp.fluid}
                        alt="Mediagrowth"
                      />
                    </Parallax>
                  </section>
                  <Observer {...options} onChange={this.serviceList}>
                    <section className={`sectionContent sectionContentFirst ${Style.service}`}>
                      <div className="scroll scrollTop" />
                      <div className={`inner ${Style.inner}`}>
                        <dl className={`init_a_serviceItem ${Style.serviceItem}`}>
                          <dt className={Style.serviceItemHeading}>
                            コミュニティ
                            <br />
                            の成長支援
                          </dt>
                          <dd className={Style.serviceItemDescription}>ユーザーと継続的に接触できるメディアを「コミュニティ」として捉えています。そのため、リアルイベントとの連動を考慮したり、公開後もデータ分析や改善施策を行なったりするなど、ウェブサイトを「作って終わり」ではなく、継続的なコミュニケーションの場として捉え、関係が活性化する支援を行ないます。</dd>
                        </dl>
                        <dl className={`init_a_serviceItem ${Style.serviceItem}`}>
                          <dt className={Style.serviceItemHeading}>
                            サイトの
                            <br />
                            保守管理
                          </dt>
                          <dd className={Style.serviceItemDescription}>ウェブサイトは企業や組織の「顔」というより、事業活動そのものを可視化する存在になりつつあります。運用フェーズにおいても、スピーディに更新・改修・拡張を必要とするため、定期的なマネジメントを必要とするケースがあります。専門知識やスキルを有したアドバイザー兼オペレーターとしてサポートします。</dd>
                        </dl>
                        <dl className={`init_a_serviceItem ${Style.serviceItem}`}>
                          <dt className={Style.serviceItemHeading}>
                            トレーニング
                            <br />
                            ＆サポート
                          </dt>
                          <dd className={Style.serviceItemDescription}>「定期的にプロダクトの振り返りや改善をしたい」「SNSで何を発信すればいいのか分からない」「社内のウェブ担当者のスキルを育成したい」など、ウェブ運用を取り巻く課題は無数に発生します。弊社では運用状況をヒアリングした上で、ウェブ担当者に併走するパートナーとなり、課題解決に導きます。</dd>
                        </dl>
                      </div>
                    </section>
                  </Observer>
                  <Observer {...optionsSkill} onChange={this.serviceSkill}>
                    <section className={`sectionContent ${Style.skillup}`}>
                      <div className={`inner init_a_serviceSkill ${Style.inner}`}>
                        <div className={Style.skillupIllustration}>
                          <Img
                            className={Style.illustrationImg}
                            fluid={data.badge.childImageSharp.fluid}
                            alt="Mediagrowth"
                          />
                        </div>
                        <div className={Style.right}>
                          <h4>ウェブサイトの定期健康診断</h4>
                          <p>人間と同じく、ウェブサイトも定期的に診察することで、異常が見つかったり改善すべきポイントが見えてきます。独自の指標と解析ツールを用いてデータを分析し、運用に課題がないかをチェックします。</p>
                        </div>
                      </div>
                    </section>
                  </Observer>
                </main>
              </ParallaxProvider>
              <Footer transition={transition} />
            </Transition>
          </Layout>
        )}
      />
    );
  }
}

export default Mediagrowth;
